import Header from "../components/Header"


function AboutUspage() {
  return (

    <>

      <Header val={3} />
      <div className="flex justify-center items-center">
        <h2>About us</h2>
      </div>

    </>
  )
}
export default AboutUspage
