

const Spinner = () => {
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <div className="spinner" ></div>
    </div>
  );
};

export default Spinner;
