export const WORD_FILE_TYPE = "words";
export const WORD_FILE_NAME = "Flashcards";


export const FLASH_CARD_SEEN="Flashcards-Seen" 
export const FLASH_CARD_UNSEEN="Flashcards-Unseen"

export const STORY_FILE_TYPE = "sampleStory";
export const STORY_FILE_NAME = "Story";

export const NOTES_FILE_TYPE = "notes";
export const NOTES_FILE_NAME = "My Notes";


